// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-imprint-js": () => import("./../../../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-verlagsprogramm-js": () => import("./../../../src/pages/verlagsprogramm.js" /* webpackChunkName: "component---src-pages-verlagsprogramm-js" */),
  "component---src-templates-author-template-jsx": () => import("./../../../src/templates/AuthorTemplate.jsx" /* webpackChunkName: "component---src-templates-author-template-jsx" */),
  "component---src-templates-publication-template-jsx": () => import("./../../../src/templates/PublicationTemplate.jsx" /* webpackChunkName: "component---src-templates-publication-template-jsx" */),
  "component---src-templates-publications-category-template-jsx": () => import("./../../../src/templates/PublicationsCategoryTemplate.jsx" /* webpackChunkName: "component---src-templates-publications-category-template-jsx" */)
}

